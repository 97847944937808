<template>
  <v-container
    id="regular-tables-view"
    fluid
    tag="section"
  >
    <snack-bar ref="snackBar" />
    <list-detail-show
      ref="listDetailShow"
      :showData="configurationListDetail"
    >
    </list-detail-show>
    <v-dialog
      v-model="configurationListCopyTextDialog"
      max-width="600"
      persistent
    >
      <v-card>
        <v-toolbar
          color="primary"
          dark
        >Please Copy The Content</v-toolbar>
        <v-card-text>
          <div class="mr-2">
            {{ configurationListCopyText }}
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="configurationListCopyTextDialog = false"
          >Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="800"
      v-model="configurationListDetailDialog"
    >
      <v-card>
        <v-toolbar
          color="primary"
          dark
        >
          ID: {{ currentView.ID }}, Model: {{ currentView.modelName }}
        </v-toolbar>
        <v-card-text>
          <v-col
            cols="12"
          >
            <v-data-table
              :headers="configurationPartHeader"
              :items="configurationPartData"
              dense
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                  <v-toolbar-title>Configuration Part</v-toolbar-title>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-col>
          <v-col
            cols="12"
          >
            <v-data-table
              :headers="equipmentStatusHeader"
              :items="equipmentStatusData"
              dense
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                  <v-toolbar-title>Equipment Status</v-toolbar-title>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-col>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="configurationListDetailDialog = false"
          >Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    -->
    <material-card
      icon="mdi-clipboard-text"
      icon-small
      title="Configuration List"
      color="accent"
    >
      <v-card>
        <v-row class="mx-2">
          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="modelFilter.brand"
              :items="equipmentModelBrands"
              label="Brand"
              item-text="brand"
              item-value="brand"
              @change="loadModelGeneration()"
            >
            </v-select>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="modelFilter.generation"
              :items="equipmentModelGenerations"
              label="Generation"
              item-text="generation"
              item-value="generation"
            >
            </v-select>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="filter.status"
              label="Status"
              :items="configurationListStatus"
              item-text="name"
              item-value="value"
            >
            </v-select>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-btn
              class="mt-2"
              min-width="120px"
              color="primary"
              @click="loadConfigurationList()"
            >
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
      <v-row>
        <v-col
          cols="12"
          md="9"
        >
          <v-card
            v-for="(item, index) in configurationListData"
            :key="item.id"
            class="pr-2 configuration-list-view"
          >
            <v-card-title class="text-h5 font-weight-bold">
              ID: {{ item.id }} - Name:  {{ item.name }} - Model: {{ item.fullModelName }}
            </v-card-title>
            <v-card-text>
              <v-card-actions>
                Available: {{ item.available }} Hold: {{ item.hold }} Sold: {{ item.sold }}
                <v-spacer></v-spacer>
                <v-icon
                  small
                  class="mr-3"
                  @click="showConfigurationList(item)"
                >
                  mdi-file-find
                </v-icon>
                <v-icon
                  small
                  class="mr-3"
                  @click="copyConfigurationList(item)"
                >
                  mdi-content-copy
                </v-icon>
                <v-icon
                  v-if="!selectedExportList.includes(item.id)"
                  small
                  class="mr-3"
                  @click="exportConfigurationListIds('add',item.id)"
                >
                  mdi-plus
                </v-icon>
                <v-icon
                  v-else
                  small
                  class="mr-3"
                  @click="exportConfigurationListIds('remove',item.id)"
                >
                  mdi-minus-circle-outline
                </v-icon>
              </v-card-actions>
              <v-data-table
                dense
                :items="item.content"
                :headers="configurationPartHeader"
                :items-per-page="item.content.length"
              >
                <template v-slot:top>
                  <v-toolbar
                    flat
                  >
                    <v-toolbar-title>Configuration Detail</v-toolbar-title>
                  </v-toolbar>
                </template>
              </v-data-table>
              <v-data-table
                dense
                :items="item.equipment_summary"
                :headers="configurationEquipmentsSummaryHeader"
                :items-per-page="item.content.length"
              >
                <template v-slot:top>
                  <v-toolbar
                    flat
                  >
                    <v-toolbar-title>Equipments List</v-toolbar-title>
                  </v-toolbar>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="recheckEquipmentsConfiguration"
            >
              Manual Update
            </v-btn>
          </v-card-actions>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-card class="mx-2 mt-2">
            <v-card-title>Select Export List By IDs</v-card-title>
            <v-card-text>
              <v-chip
                v-for="(item,key) in selectedExportList"
                :key="key"
                class="ma-2"
                dense
                color="info"
                @click="exportConfigurationListIds('remove',item)"
              >
                ID: {{ item }}
              </v-chip>
            </v-card-text>
            <v-card-actions>
              <v-btn
                :disabled="disableExportButton"
                dense
                block
                color="primary"
                @click="requestExportConfigurationListIds()"
              >
                Export Configuration List
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-card class="mx-2 mt-2">
            <v-card-title>
              Select Export List By Date
            </v-card-title>
            <v-card-text>
              <v-col cols="12">
                <v-text-field
                  v-model="configurationListExportOptions.from"
                  type="date"
                  label="Date From"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="configurationListExportOptions.to"
                  type="date"
                  label="Date To"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="configurationListExportOptions.type"
                  :items="[{name:'Created', value:'created'}, {name:'Updated', value:'updated'}]"
                  item-text="name"
                  item-value="value"
                  label="Select date by"
                >
                </v-select>
              </v-col>
            </v-card-text>
            <v-card-actions>
              <v-btn
                :disabled="disableExportButton"
                dense
                block
                color="primary"
                @click="requestExportConfigurationListDate()"
              >
                Export Configuration List
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </material-card>
    <div class="text-center">
      <v-pagination
        v-model="pageInfo.currentPage"
        :length="pageInfo.total"
        @input="changePageTo"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
  import equipmentModelApi from '@/api/model'
  import configurationListApi from '@/api/equipment-configuration-list'
  import SnackBar from '@/components/app/SnackBar'
  import ListDetailShow from '@/components/configuration/ListDetailShow'
  import { get } from 'vuex-pathify'
  import store from '@/store'
  export default {
    name: 'ConfigurationList',
    components: { SnackBar, ListDetailShow },
    data () {
      return {
        modelFilter: {
          type: '',
          brand: 'All',
          generation: 'All',
        },
        equipmentModelBrands: [],
        equipmentModelGenerations: [{ generation: 'All' }],
        configurationListStatus: [
          { name: 'All', value: 'All' },
          { name: 'Available', value: 1 },
          { name: 'Disabled', value: 0 },
        ],
        filter: {
          status: 1,
          brand: 'All',
          generation: 'All',
        },
        pageInfo: {
          currentPage: 1,
          total: 1,
          next: 1,
        },
        /*
        configurationListHeader: [
          {
            text: 'ID',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'Name', value: 'name' },
          { text: 'Model', value: 'fullModelName' },
          { text: 'Available', value: 'availableEquipments' },
          { text: 'Hold', value: 'holdEquipments' },
          { text: 'Sold', value: 'soldEquipments' },
          { text: 'Updated (UTC)', value: 'updated_at' },
          { text: 'Status', value: 'status' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        */
        configurationListData: [],
        configurationListDetailDialog: false,
        configurationListCopyTextDialog: false,
        configurationListCopyText: '',
        currentView: {
          ID: '',
          configurationListName: '',
          modelName: '',
        },
        configurationListDetail: {},
        configurationPartHeader: [
          {
            text: 'ID',
            align: 'start',
            sortable: true,
            value: 'item_id',
          },
          { text: 'Part', value: 'info.part_number' },
          { text: 'Description', value: 'info.description' },
          { text: 'Type', value: 'info.item_type.name' },
          { text: 'Bin', value: 'info.bin_number' },
          { text: 'Qty', value: 'quantity' },
        ],
        configurationEquipmentsSummaryHeader: [
          {
            text: 'ID',
            align: 'start',
            sortable: true,
            value: 'item_id',
          },
          { text: 'SOS PN', value: 'part_number' },
          { text: 'Description', value: 'description' },
          { text: 'Bin', value: 'bin_number' },
          { text: 'Type', value: 'type' },
          { text: 'Available', value: 'available' },
          { text: 'Hold', value: 'hold' },
          { text: 'Sold', value: 'sold' },
        ],
        /*
        configurationPartHeader: [
          {
            text: 'ID',
            align: 'start',
            sortable: true,
            value: 'item_id',
          },
          { text: 'Part', value: 'info.part_number' },
          { text: 'Type', value: 'info.item_type.name' },
          { text: 'Bin', value: 'info.bin_number' },
          { text: 'Qty', value: 'quantity' },
          { text: 'Status', value: 'status' },
        ],
        configurationPartData: [],
        equipmentStatusHeader: [
          {
            text: 'ID',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'PN', value: 'item.part_number' },
          { text: 'Type', value: 'item.item_type.name' },
          { text: 'BIN', value: 'item.bin_number' },
          { text: 'SN', value: 'serial_number' },
          { text: 'Status', value: 'equipment_status.name' },
        ],
        equipmentStatusData: [],
        */
        disableExportButton: false,
        configurationListExportOptions: {
          from: '',
          to: '',
          type: 'created',
        },
      }
    },
    computed: {
      ...get('equipment', ['selectedExportList']),
    },
    mounted () {
      this.loadModelBrand()
      this.loadConfigurationList()
    },
    methods: {
      loadModelBrand () {
        this.modelFilter.type = 'brand'
        equipmentModelApi.filter(this.modelFilter).then(response => {
          this.equipmentModelBrands = response.data.data
          this.equipmentModelBrands.push({ brand: 'All' })
        }).catch(error => {
          console.log(error)
        })
      },
      loadModelGeneration () {
        this.modelFilter.type = 'generation'
        equipmentModelApi.filter(this.modelFilter).then(response => {
          this.equipmentModelGenerations = response.data.data
          this.equipmentModelGenerations.push({ generation: 'All' })
        })
      },
      changePageTo (page) {
        console.log(page)
        this.pageInfo.next = page
        this.loadConfigurationList()
      },
      loadConfigurationList () {
        this.filter.brand = this.modelFilter.brand
        this.filter.generation = this.modelFilter.generation
        this.modelFilter.type = 'brand'
        this.modelFilter.color = 'info'
        store.set('message/storeMessage', {
          color: 'info',
          text: 'Due to heavy data loading, please wait for a moment',
          timeout: 3000,
        })
        this.$refs.snackBar.activeStatusInfo()
        configurationListApi.index(this.filter, this.pageInfo.next).then(response => {
          if (response.data.code === 200) {
            this.configurationListData = this.handleConfigurationList(response.data.data.data)
            this.pageInfo.total = response.data.data.last_page
            this.pageInfo.currentPage = response.data.data.current_page
          } else {
            store.set('message/storeMessage', {
              color: 'red',
              text: response.data.message,
              timeout: 3000,
            })
            this.$refs.snackBar.activeStatusInfo()
          }
        }).catch(error => {
          store.set('message/storeMessage', {
            color: 'red',
            text: error,
            timeout: 3000,
          })
          this.$refs.snackBar.activeStatusInfo()
        })
      },
      recheckEquipmentsConfiguration () {
        configurationListApi.recheckConfiguration().then(response => {
          console.log(response)
        })
      },
      showConfigurationList (item) {
        this.configurationListDetail = item
        this.$refs.listDetailShow.configurationListDetailDialog = true
        /*
        this.currentView = {
          ID: item.id,
          configurationListName: item.name,
          modelName: item.fullModelName,
        }
        this.equipmentStatusData = item.equipment_record_ids
        this.configurationPartData = item.content
        this.configurationListDetailDialog = true
         */
      },
      copyConfigurationList (item) {
        const parts = item.content
        let text = ''
        parts.forEach((element, index) => {
          const number = index + 1
          const content = number + '. Item Part Number: ' + element.info.part_number +
            ' Item Description: ' + element.info.description + ' Item Type: ' + element.info.item_type.name +
            ' Quantity: ' + element.quantity + '; '
          text = text + content
        })
        this.configurationListCopyText = text
        this.configurationListCopyTextDialog = true
      },
      handleConfigurationList (data) {
        data.forEach((element) => {
          element.fullModelName = element.equipment_brand + '-' + element.equipment_generation + '-' + element.equipment_name
          element.is_active === 1 ? element.status = 'Active' : element.status = 'Disable'
        })
        return data
      },
      exportConfigurationListIds (method, id) {
        const list = []
        this.selectedExportList.forEach((element) => {
          list.push(element)
        })
        if (method === 'add') list.push(id)
        if (method === 'remove') {
          const indexOfListId = list.indexOf(id)
          list.splice(indexOfListId, 1)
        }
        store.set('equipment/selectedExportListData', list)
      },
      requestExportConfigurationListIds () {
        this.activeDisableExportButton()
        configurationListApi.exportListingConfigurationList({
          type: 'select',
          select_configuration: this.selectedExportList.toString(),
        }).then(response => {
          if (response.data.code !== 200) {
            store.set('message/storeMessage', {
              color: 'red',
              text: response.data.message,
              timeout: 3000,
            })
            this.$refs.snackBar.activeStatusInfo()
          }
        }).catch(error => {
          store.set('message/storeMessage', {
            color: 'red',
            text: error,
            timeout: 3000,
          })
          this.$refs.snackBar.activeStatusInfo()
        }).finally(() => {
          this.disableExportButton = false
        })
      },
      requestExportConfigurationListDate () {
        this.activeDisableExportButton()
        configurationListApi.exportListingConfigurationList(this.configurationListExportOptions).then(response => {
          if (response.data.code !== 200) {
            store.set('message/storeMessage', {
              color: 'red',
              text: response.data.message,
              timeout: 3000,
            })
            this.$refs.snackBar.activeStatusInfo()
          }
        }).catch(error => {
          store.set('message/storeMessage', {
            color: 'red',
            text: error,
            timeout: 3000,
          })
          this.$refs.snackBar.activeStatusInfo()
        }).finally(() => {
          this.disableExportButton = false
        })
      },
      activeDisableExportButton () {
        this.disableExportButton = true
        store.set('message/storeMessage', {
          color: 'info',
          text: 'Please do not close browser or close this page until button back to active',
          timeout: 3000,
        })
        this.$refs.snackBar.activeStatusInfo()
      },
    },
  }
</script>

<style scoped>
.configuration-list-view {
  color: #0D0D0D;
  margin-bottom: 6px;
  margin-top: 1%;
}
</style>
